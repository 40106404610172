<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs" />
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="board" :padding="false">
              <template v-slot:body>
                <div class="scrollX">
                  <TableWithCheckbox
                    ref="table"
                    :labels="labels"
                    :data-table="dataTable"
                    @select-items="handleSelectedItems"
                    :count="notificationCount"
                  />
                </div>
              </template>
              <template v-slot:footer>
                 <PaginationLog
                  :listLength="notificationCount"
                  :modulePath="modulePath"
                  ref="pagination"
                />
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid">
          <li class="listGrid-item">
            <button class="btn btn-red" type="button" @click="handleDelete">削除する</button>
          </li>
          <li class="listGrid-item pos-end">
            <router-link v-if="isOfficeHadEvent" class="btn btn-main" :to="{ name: 'NotificationRegister' }">新規登録</router-link>
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
    <Modal @close="closeModal" v-if="modal">
      <template v-slot:headline>削除</template>
      <template v-slot:body>
        <p class="description text-align-center">選択した通知を削除しますか？</p>
        <ul class="listGrid justify-content-center">
          <li class="listGrid-item">
            <button class="btn btn-lg btn-white" type="button" @click="closeModal">キャンセル</button>
          </li>
          <li class="listGrid-item">
            <ActionButton class="btn btn-lg btn-red" :handle-submit="deleteList" button-text="削除する" />
          </li>
        </ul>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
//component
import TableWithCheckbox from '@/components/TableWithCheckbox.vue';
import PaginationLog from '@/components/PaginationLog.vue';
import Modal from '@/components/Modal.vue';
//mixin
import nav from '@/mixins/nav/notification';
import checkall from '@/mixins/plugin/checkall';
import modal from '@/mixins/plugin/modal';
//helper
import { formatDate, formatBetweenTwoDates } from '@/helpers/formatData';

export default {
  name: 'Notification',
  data: function() {
    return {
      pageName: '通知',
      modulePath: 'notification/getNotificationList',
      selectedNotifications: [],
      labels: [
        { isCheckbox: true },
        { key: 'createDate', name: '登録日' },
        { key: 'message', name: '本文', tdClass: 'dataTable-wrap' },
        { key: 'target', name: '対象' },
        { key: 'publicDate', name: '期間' },
      ],
    };
  },
  mixins: [nav, checkall, modal],
  computed: {
    ...mapGetters({
      notificationList: 'notification/notificationList',
      notificationCount: 'notification/notificationCount',
      isOfficeHadEvent: 'auth/isOfficeHadEvent',
    }),
    dataTable() {
      return this.notificationList.map(item => {
        return {
          ...item,
          createDate: formatDate(item.createDate),
          publicDate: formatBetweenTwoDates(item.publicDate?.startDate, item.publicDate?.endDate),
          target: this.getTarget(item.target),
        }
      })
    }
  },
  components: {
    TableWithCheckbox,
    PaginationLog,
    Modal,
  },
  methods: {
    handleSelectedItems(items) {
      this.selectedNotifications = items;
    },
    handleDelete() {
      if (this.selectedNotifications?.ids?.length > 0 || this.selectedNotifications?.allFlag) {
        this.showModal();
      } else {
        this.$message.noSelectedItem();
      }
    },
    async deleteList() {
      const result = await this.$store.dispatch('notification/deleteNotificationList', this.selectedNotifications);
      this.closeModal();
      this.$refs.table.resetCheck();
      if (result) {
        this.$message.deleted('notification');
        this.$refs.pagination.resetPagination();
      }
    },
    getTarget(target) {
      if (target?.event && target?.shop) {
        return target.event.name + ' > ' + target.shop.name;
      } else if (target?.event) {
        return target.event.name;
      } else if (target?.shop) {
        return target.shop.name;
      }
      return '-';
    },
  }
};
</script>
